
import { Component, Mixins } from 'vue-property-decorator';
import { Context, Franchise, Office } from 'client-website-ts-library/types';
import { API, Config } from 'client-website-ts-library/services';
import { View } from 'client-website-ts-library/plugins';
import Form from '@/components/Forms/Form.vue';
import { ContextItemType } from 'client-website-ts-library/types/Context';

@Component({
  components: {
    Form,
  },
})
export default class ContactUs extends Mixins(View) {
  private franchise: Franchise | null = null;

  private formCtx: Context | null = null

  mounted() {
    API.Franchises.Get(Config.Website.Settings!.WebsiteId).then((franchise) => {
      this.franchise = franchise;
    });

    const ctx: Context = {
      ClientWebsiteId: Config.Website.Id,
      Items: [
        {
          Type: ContextItemType.Office,
          Id: '97eea4c0-6832-44d3-9b4a-8639d06b4804', // SALES OFFICE
        },
        {
          Type: ContextItemType.EnquiryTargetProfile,
          Id: '612d84b4-0122-4ab2-bea8-2c6175d3acf0', // SALES
        },
      ],
    };

    this.formCtx = ctx;
  }
}
